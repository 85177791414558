
import { defineComponent, ref, onMounted, watch } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { modalShowListener } from "@/core/directive/function/common";
import { ApiBase, ApiPayment } from "@/core/api";
import mixin from "@/mixins";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import { TaggingItem } from "@/core/directive/interface/common";

export default defineComponent({
  name: "add-payment-modal",
  props: {
    id: { type: String, required: false, default: "" },
  },
  components: {},
  emits: ["update-list"],
  setup(props, { emit }) {
    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const addPaymentModalRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const { t } = useI18n();
    const editId = ref(props.id);

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      id: "",
      platform_id: "",
      customer: {},
      ocps_customer_id: "",
      customer_name: "",
      reference_order_no: "",
      description: "",
      amount: 0,
      currency: "EUR",
      customer_email: "",
      status: "",
    });

    const rules = ref({
      platform_id: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      ocps_customer_id: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      amount: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      description: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    watch(
      () => props.id,
      (newVal) => {
        editId.value = newVal;
        if (newVal) {
          formData.value.id = newVal;
          getPaymentData();
        } else {
          resetForm();
        }
      }
    );

    const options = ref({
      platform: [] as TaggingItem[],
      customer: [] as TaggingItem[],
      customerLoading: false,
    });

    const getTaggingData = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: ["payment_platform"],
      });
      if (data.code == 0) {
        options.value.platform = data.data.payment_platform.items;
      }
    };

    const getCustomerOptions = async (query: string) => {
      options.value.customerLoading = true;
      const { data } = await ApiBase.getCustomerSourceData({ name: query });
      options.value.customerLoading = false;
      if (data.code == 0) {
        options.value.customer = data.data;
      }
    };

    const debounceCustomerSearch = _.debounce(getCustomerOptions, 1000);

    const searchCustomerItems = (query: string) => {
      debounceCustomerSearch(query);
    };

    const customerChange = (e) => {
      formData.value.ocps_customer_id = e.value;
      formData.value.customer_name = e.label;
    };

    const getPaymentData = async () => {
      loading.value = true;
      const { data } = await ApiPayment.showPayment({
        id: formData.value.id,
      });
      loading.value = false;
      if (data.code == 0) {
        formData.value = Object.assign(formData.value, data.data);
        if (data.data.customer_name) {
          options.value.customer.splice(
            0,
            options.value.customer.length,
            ...[
              {
                value: data.data.ocps_customer_id,
                label: data.data.customer_name,
              },
            ]
          );
        }
      }
    };

    const addPaymentInfo = () => {
      ApiPayment.addPayment(formData.value)
        .then(({ data }) => {
          loading.value = false;
          submitButton.value?.removeAttribute("data-kt-indicator");
          if (data.code == 0) {
            showFormSubmitSuccessMsg(() => {
              resetForm();
              hideModal(addPaymentModalRef.value);
              emit("update-list");
            });
          } else {
            showServerErrorMsg(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const editPaymentInfo = () => {
      ApiPayment.updatePayment(formData.value)
        .then(({ data }) => {
          loading.value = false;
          submitButton.value?.removeAttribute("data-kt-indicator");
          if (data.code == 0) {
            showFormSubmitSuccessMsg(() => {
              resetForm();
              hideModal(addPaymentModalRef.value);
              emit("update-list");
            });
          } else {
            showServerErrorMsg(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          if (editId.value) {
            editPaymentInfo();
          } else {
            addPaymentInfo();
          }
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const handleDiscard = () => {
      resetForm();
      hideModal(addPaymentModalRef.value);
    };

    const resetForm = () => {
      formRef.value?.resetFields();
      formData.value = {
        id: "",
        platform_id: "",
        customer: {},
        ocps_customer_id: "",
        customer_name: "",
        reference_order_no: "",
        description: "",
        amount: 0,
        currency: "EUR",
        customer_email: "",
        status: "",
      };
    };

    onMounted(() => {
      getTaggingData();
      modalShowListener(addPaymentModalRef.value, () => {
        setTimeout(() => {
          if (editId.value) {
            getPaymentData();
          }
        });
      });
    });

    return {
      props,
      loading,
      options,
      formData,
      rules,
      formRef,
      editId,
      addPaymentModalRef,
      submitButton,
      t,
      submit,
      handleDiscard,
      resetForm,
      searchCustomerItems,
      customerChange,
    };
  },
});
